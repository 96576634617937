/* screen breaks */
/** menu heights **/
/* screen breaks */
/** menu heights **/
/** Variables and mixins for screen breaks **/
.landingpage .dj-image {
  width: 100% !important; }

.landingpage section {
  margin: 0; }

.landingpage .column-small {
  lost-column: 1/4 0 10px; }

.landingpage .column-large {
  lost-column: 3/4 0 10px;
  flex-flow: column; }

.landingpage .column-large > div, .landingpage .column-small > div {
  margin-bottom: 10px; }

.landingpage .landingpage-content {
  background-color: #555;
  lost-flex-container: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 4em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  .landingpage .landingpage-content * {
    color: white;
    text-align: center; }
  .landingpage .landingpage-content p {
    width: 100%; }

.landingpage .landingpage-header, .landingpage #landing-headline {
  margin-top: 2em; }

.landingpage .landingpage-header .landingpage-content {
  height: 100%; }

.landingpage .image-wrapper {
  width: 100%; }
  .landingpage .image-wrapper img {
    width: 100% !important;
    height: auto; }

.landingpage .landingpage-actions a {
  display: inline-block;
  margin-right: 10px;
  border: 2px solid #E8E8E8;
  padding: 10px 20px; }
  .landingpage .landingpage-actions a span {
    display: flex;
    justify-content: center;
    text-transform: uppercase; }
    .landingpage .landingpage-actions a span i {
      padding-right: 5px; }
  .landingpage .landingpage-actions a:hover {
    cursor: pointer; }
